import { render, staticRenderFns } from "./FormCard.vue?vue&type=template&id=43aba06d&scoped=true&"
import script from "./FormCard.vue?vue&type=script&lang=js&"
export * from "./FormCard.vue?vue&type=script&lang=js&"
import style0 from "./FormCard.vue?vue&type=style&index=0&id=43aba06d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43aba06d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCard,VCardText,VCol,VFileInput,VRow,VSelect,VTextField,VTextarea})
