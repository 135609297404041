<template>
  <div class="ma-3 pa-3 px-lg-16 mx-lg-1 py-lg-6 my-lg-6">
    <v-row>
      <v-col
        cols="12"
        md="8"
        :class="{ 'text-center px-0': $vuetify.breakpoint.xs }"
      >
        <h1 v-text="$t('myActivity.myGroups')" class="px-0 mb-5" />
        <h2
          v-text="$t('groups.hereYouCanManageTheGroupsOfEachSchool')"
          class="pb-12"
        />
      </v-col>
    </v-row>
    <v-select
      v-if="selectedSchoolSlug"
      v-model="selectedSchoolSlug"
      class="schoolSelect mx-auto"
      @change="fetchGroups(true)"
      :label="$tc('general.school', 1)"
      :items="schoolsSelectList"
    />
    <v-row class="pt-10 ml-0" justify="space-around">
      <v-col
        cols="12"
        sm="6"
        lg="4"
        class="py-10"
        v-for="group in groupList"
        :key="group.id"
      >
        <info-card
          :title="group.name"
          :imgUrl="group.activityLogo"
          :buttonText="$t('myActivity.forGroupDetails')"
          buttonColor="primary"
          @click="
            $router.push({
              name: 'GatekeeperGroupDetail',
              params: { groupSlug: group.slug },
            })
          "
        >
          <template v-slot:subtitle>
            {{ group.activityName }}
          </template>
          <title-to-text
            class="mb-1"
            :title="$t('myActivity.groupDescription')"
            :text="trimText(group.description, 21) || $t('errors.empty')"
          />
          <title-to-text
            class="my-0"
            :title="$t('myActivity.studentsNumberInGroup')"
            :text="group.consumers.length"
          />
        </info-card>
      </v-col>
    </v-row>
    <div class="text-center pt-10 overline">
      {{ totalGroups || 0 }} {{ $t("myActivity.groupsFound") }}
    </div>
    <end-of-page-detector @end-of-page="onEndOfPage" />
  </div>
</template>
<script>
import store from "@/vuex/store"
import { mapActions, mapState } from "vuex"
import { trimText } from "@/filters"
import EndOfPageDetector from "@/components/EndOfPageDetector"
import InfoCard from "@/components/InfoCard"
import TitleToText from "@/components/TitleToText.vue"
import { SERVER } from "@/helpers/constants/constants"
import introjsSubscribeMixin from "@/mixins/introJs/introjsSubscribeMixin"

export default {
  name: "GatekeeperGroups",
  components: {
    EndOfPageDetector,
    InfoCard,
    TitleToText,
  },
  mixins: [introjsSubscribeMixin],
  async beforeRouteEnter(to, from, next) {
    await store.dispatch("pagination/flushState")
    await store.dispatch("pagination/updatePagination", { itemsPerPage: 500 })
    const schools = await store.dispatch("gatekeeperSchool/getSchoolList", {
      override: true,
    })
    await store.dispatch("pagination/flushState")
    if (!schools.length) {
      return next()
    }
    await store.dispatch("gatekeeperProgramGroup/getGroupList", {
      groupType: SERVER.programGroupTypes.standard,
      schoolSlug: schools[0].slug,
      override: true,
    })
    return next(vm => (vm.selectedSchoolSlug = schools[0].slug))
  },
  computed: {
    ...mapState("gatekeeperProgramGroup", ["totalGroups", "groupList"]),
    ...mapState("gatekeeperSchool", ["schoolList"]),
    ...mapState("pagination", ["page"]),
    schoolsSelectList() {
      return this.schoolList.map(school => ({
        text: school.name,
        value: school.slug,
      }))
    },
  },
  methods: {
    ...mapActions("pagination", ["incrementPage", "flushState"]),
    ...mapActions("gatekeeperProgramGroup", ["getGroupList"]),
    trimText,
    onEndOfPage() {
      this.incrementPage()
    },
    fetchGroups(override = false) {
      if (override) {
        this.flushState()
      }
      if (override || this.groupList.length < this.totalGroups) {
        this.getGroupList({
          groupType: SERVER.programGroupTypes.standard,
          schoolSlug: this.selectedSchoolSlug,
          override,
        })
      }
    },
  },
  data() {
    return {
      selectedSchoolSlug: null,
    }
  },
  watch: {
    page() {
      this.fetchGroups()
    },
  },
}
</script>
<style lang="scss" scoped>
.schoolSelect {
  width: 500px;
}
</style>
