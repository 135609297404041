import axios from "axios"
import { GET_THEME_API_URL } from "@/helpers/constants/constants"

const theme = {
  getTheme(params = {}) {
    return axios.get(GET_THEME_API_URL, { params })
  },
  updateTheme(slug, theme) {
    if (!slug) throw "updateTheme: received empty slug"
    return axios.patch(`${GET_THEME_API_URL}${slug}/`, theme)
  },
}

export default theme
