<template>
  <div :style="style" class="dashboard-wrapper">
    <navbar user-type="coordinator" />
    <router-view />
    <base-footer />
  </div>
</template>
<script>
import CoordinatorDashboard from "connective/layouts/CoordinatorDashboard"
import BaseFooter from "@/components/BaseFooter"

export default {
  components: { BaseFooter },
  extends: CoordinatorDashboard,
}
</script>
<style scoped>
.dashboard-wrapper {
  margin-bottom: 100px;
}
</style>
