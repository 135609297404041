import store from "connective/vuex/store"
import theme from "@/vuex/modules/theme"
import gatekeeper from "@/vuex/modules/gatekeeper"
import gatekeeperProgram from "@/vuex/modules/gatekeeperProgram"
import gatekeeperInvite from "@/vuex/modules/gatekeeperInvite"
import gatekeeperEvent from "@/vuex/modules/gatekeeperEvent"
import gatekeeperSchool from "@/vuex/modules/gatekeeperSchool"
import gatekeeperProgramGroup from "@/vuex/modules/gatekeeperProgramGroup"

store.registerModule("theme", theme)
store.registerModule("gatekeeper", gatekeeper)
store.registerModule("gatekeeperProgram", gatekeeperProgram)
store.registerModule("gatekeeperInvite", gatekeeperInvite)
store.registerModule("gatekeeperEvent", gatekeeperEvent)
store.registerModule("gatekeeperSchool", gatekeeperSchool)
store.registerModule("gatekeeperProgramGroup", gatekeeperProgramGroup)

export default store
