var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-3 pa-3 px-lg-16 mx-lg-16 py-lg-6 my-lg-6"},[_c('v-row',[_c('v-col',{class:{ 'text-center px-0': _vm.$vuetify.breakpoint.xs },attrs:{"cols":"12","md":"8"}},[_c('h1',{staticClass:"px-0 mb-5",domProps:{"textContent":_vm._s(_vm.$t('myActivity.myGroups'))}}),_c('h2',{staticClass:"pb-12",domProps:{"textContent":_vm._s(
          _vm.$t(
            'myActivity.hereYouCanSeeAllTheGroupsOfTheRunningProgramsOfTheSchool'
          )
        )}})]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"d-block mx-auto",attrs:{"introjs":"add-btn","tile":"","large":"","color":"success","data-testid":"create-group","block":_vm.$vuetify.breakpoint.xs},on:{"click":function($event){return _vm.$router.push({ name: 'GroupEditor' })}}},[_vm._v(" "+_vm._s(_vm.$t("groups.newGroup"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-plus ")])],1)],1)],1),_c('v-row',{staticClass:"pt-10 ml-0",attrs:{"justify":"space-around"}},_vm._l((_vm.groupList),function(group){return _c('v-col',{key:group.id,staticClass:"py-10",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('info-card',{attrs:{"title":group.name,"imgUrl":group.activityLogo,"buttonText":_vm.$t('myActivity.forGroupDetails'),"buttonColor":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'GroupDetail',
            params: { groupSlug: group.slug },
          })}},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(" "+_vm._s(group.activityName)+" ")]},proxy:true}],null,true)},[_c('title-to-text',{staticClass:"mb-1",attrs:{"title":_vm.$t('myActivity.groupDescription'),"text":_vm.trimText(group.description, 21) || _vm.$t('errors.empty')}}),_c('title-to-text',{staticClass:"my-0",attrs:{"title":_vm.$t('myActivity.studentsNumberInGroup'),"text":group.consumers.length}})],1)],1)}),1),_c('div',{staticClass:"text-center pt-10 overline"},[_vm._v(" "+_vm._s(_vm.totalGroups)+" "+_vm._s(_vm.$t("myActivity.groupsFound"))+" ")]),_c('end-of-page-detector',{on:{"end-of-page":_vm.onEndOfPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }