import axios from "axios"
import {
  GET_GATEKEEPER_COORDINATORS_LIST_API_URL,
  ADD_GATEKEEPER_COORDINATORS_API_URL,
  EDIT_GATEKEEPER_COORDINATORS_API_URL,
  DELETE_GATEKEEPER_SCHOOL_COORDINATORS_API_URL,
  GET_GATEKEEPER_VENDORS_LIST_API_URL,
  ADD_GATEKEEPER_VENDORS_API_URL,
  EDIT_GATEKEEPER_VENDORS_API_URL,
  DELETE_GATEKEEPER_SCHOOL_VENDORS_API_URL,
} from "@/helpers/constants/constants"

const gatekeeperInvite = {
  getCoordinatorList(params = {}) {
    return axios.get(GET_GATEKEEPER_COORDINATORS_LIST_API_URL, { params })
  },

  addCoordinator(coordinator) {
    return axios.post(ADD_GATEKEEPER_COORDINATORS_API_URL, coordinator)
  },

  editCoordinator(slug, data) {
    if (!slug) throw "editCoordinator: received empty slug"
    return axios.patch(`${EDIT_GATEKEEPER_COORDINATORS_API_URL}${slug}/`, data)
  },

  deleteCoordinators(coordinatorSlugs) {
    return Promise.all(
      coordinatorSlugs.map(slug =>
        axios.delete(`${DELETE_GATEKEEPER_SCHOOL_COORDINATORS_API_URL}${slug}/`)
      )
    )
  },

  getVendorList(params = {}) {
    return axios.get(GET_GATEKEEPER_VENDORS_LIST_API_URL, { params })
  },

  addVendor(vendor) {
    return axios.post(ADD_GATEKEEPER_VENDORS_API_URL, vendor)
  },

  editVendor(slug, data) {
    if (!slug) throw "editVendor: received empty slug"
    return axios.patch(`${EDIT_GATEKEEPER_VENDORS_API_URL}${slug}/`, data)
  },

  deleteVendors(vendorSlugs) {
    return Promise.all(
      vendorSlugs.map(slug =>
        axios.delete(`${DELETE_GATEKEEPER_SCHOOL_VENDORS_API_URL}${slug}/`)
      )
    )
  },
}

export default gatekeeperInvite
