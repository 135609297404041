import routes from "connective/router/routes"
import guards, { chainGuards } from "@/router/guards"
import GatekeeperDashboard from "@/layouts/GatekeeperDashboard"
import GatekeeperThemeEditor from "@/views/GatekeeperThemeEditor"
import GatekeeperProfile from "@/views/Profile/GatekeeperProfile"
import GatekeeperProgramOrders from "@/views/GatekeeperProgramOrders"
import GatekeeperEvents from "@/views/GatekeeperEvents"
import GatekeeperInviteWrapper from "@/views/Invite/GatekeeperInviteWrapper"
import GatekeeperInviteCoordinators from "@/views/Invite/GatekeeperInviteCoordinators"
import GatekeeperInviteVendors from "@/views/Invite/GatekeeperInviteVendors"
import GatekeeperStatistics from "@/views/GatekeeperStatistics"
import GatekeeperGroups from "@/views/GatekeeperGroups"
import GatekeeperGroupDetail from "@/views/GatekeeperGroupDetail"
import GatekeeperAssignGroupConsumers from "@/views/GatekeeperAssignGroupConsumers"


const gatekeeperDashboard = {
  path: "gatekeeper-dashboard",
  component: GatekeeperDashboard,
  beforeEnter: chainGuards([
    guards.gatekeeperOnly,
    guards.populateGatekeeperData,
  ]),
  children: [
    {
      path: "",
      name: "GatekeeperDashboard",
      redirect: { name: "GatekeeperProgramOrders" },
    },
    {
      path: "theme-editor",
      name: "GatekeeperThemeEditor",
      component: GatekeeperThemeEditor,
    },
    {
      path: "profile",
      name: "GatekeeperProfile",
      component: GatekeeperProfile,
    },
    {
      path: "program-orders",
      name: "GatekeeperProgramOrders",
      component: GatekeeperProgramOrders,
    },
    {
      path: "events",
      name: "GatekeeperEvents",
      component: GatekeeperEvents,
    },
    {
      path: "gatekeeper-statistics",
      name: "GatekeeperStatistics",
      component: GatekeeperStatistics,
    },
    {
      path: "gatekeeper-groups",
      name: "GatekeeperGroups",
      component: GatekeeperGroups,
    },
    {
      path: "gatekeeper-group-detail/:groupSlug",
      name: "GatekeeperGroupDetail",
      component: GatekeeperGroupDetail,
      props: true,
    },
    {
      path: "gatekeeper-assign-group-students/:groupSlug",
      name: "GatekeeperAssignGroupConsumers",
      component: GatekeeperAssignGroupConsumers,
      props: true,
    },
    {
      path: "invite",
      component: GatekeeperInviteWrapper,
      children: [
        {
          path: "",
          name: "GatekeeperInviteWrapper",
          redirect: { name: "GatekeeperInviteCoordinators" },
        },
        {
          path: "invite-coordinators",
          name: "GatekeeperInviteCoordinators",
          component: GatekeeperInviteCoordinators,
        },
        {
          path: "invite-vendors",
          name: "GatekeeperInviteVendors",
          component: GatekeeperInviteVendors,
        },
      ],
    },
  ],
}
if (routes[1].path !== "/:lang" || routes[1].beforeEnter) {
  throw new Error("route is not aligned")
}

routes[1].children.push(gatekeeperDashboard)
routes[1].beforeEnter = guards.applyTheme

// add multi-tenant indication to url, and remove usage of root route ("/")

routes[1].path = "/:siteid/:lang"


routes[1].redirect = "/:siteid/:lang/welcome/login"



// routes[1].children[0].redirect = "/:siteid/:lang/welcome/login"

routes[0] = {
  path: "/",
  redirect: "/1/en/welcome/login",
}

export default routes
