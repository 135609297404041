import Api from "@/api"
import vuetify from "@/plugins/vuetify"

const theme = {
  namespaced: true,
  state: {
    theme: {
      slug: null,
      colorPrimary: null,
      colorSecondary: null,
      colorSuccess: null,
      colorSecondaryLightenOne: null,
      colorSecondaryLightenTwo: null,
      logo: null,
      welcomeBg: null,
      navbarBg: null,
      useAvatarFigure: null,
    },
  },
  mutations: {
    SET_THEME(state, theme) {
      state.theme = theme
    },
  },
  actions: {
    flushState() {
      // do not flush state for theme
      return
    },
    async getTheme({ commit, state }) {
      if (!state.theme.colorPrimary) {
        // fetch if not in cache
        const res = await Api.theme.getTheme()
        if (res.data.results.length) {
          commit("SET_THEME", res.data.results[0])
        }
      }
      return state.theme
    },
    async updateTheme({ commit, state }, { slug, theme }) {
      const res = await Api.theme.updateTheme(slug, theme)
      commit("SET_THEME", res.data)
      return state.theme
    },
    async applyThemeColor({ state }) {
      let theme = vuetify.framework.theme.themes.light
      Object.assign(theme, {
        primary: state.theme.colorPrimary || theme.primary,
        secondary: state.theme.colorSecondary || theme.secondary,
        success: state.theme.colorSuccess || theme.success,
        "secondary-lighten-1":
          state.theme.colorSecondaryLightenOne || theme["secondary-lighten-1"],
        "secondary-lighten-2":
          state.theme.colorSecondaryLightenTwo || theme["secondary-lighten-2"],
      })
    },
  },
}

export default theme
