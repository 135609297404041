export * from "connective/helpers/constants/constants"
import { SERVER, SERVER_URL } from "connective/helpers/constants/constants"

const SERVER_PRIVATE_URL = SERVER_URL.replace("/api", "/api_private")
SERVER.userTypes.gatekeeper = "GATEKEEPER"

export { SERVER }
export const GET_THEME_API_URL = `${SERVER_PRIVATE_URL}/theme/`
export const UPDATE_GATEKEEPER_PROFILE_API_URL = `${SERVER_PRIVATE_URL}/gatekeepers_profiles/`
export const GET_GATEKEEPER_PROFILE_API_URL = `${SERVER_PRIVATE_URL}/gatekeepers_profiles/me/`
export const GET_GATEKEEPER_PROGRAM_ORDERS_LIST_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_order/`
export const UPDATE_GATEKEEPER_PROGRAM_ORDER_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_order/`
export const GET_GATEKEEPER_COORDINATORS_LIST_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_coordinators/`
export const ADD_GATEKEEPER_COORDINATORS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_coordinators/`
export const EDIT_GATEKEEPER_COORDINATORS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_coordinators/`
export const DELETE_GATEKEEPER_SCHOOL_COORDINATORS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_coordinators/`
export const GET_GATEKEEPER_VENDORS_LIST_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_vendors/`
export const ADD_GATEKEEPER_VENDORS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_vendors/`
export const EDIT_GATEKEEPER_VENDORS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_vendors/`
export const DELETE_GATEKEEPER_SCHOOL_VENDORS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_vendors/`
export const GET_GATEKEEPER_EVENT_LIST_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_event/`
export const GET_GATEKEEPER_EVENT_EXPORT_FILE_API_URL = `${SERVER_PRIVATE_URL}/export_gatekeeper_event/`
export const GET_GATEKEEPER_REPORT_ATTENDANCE_AVG_PER_ACTIVITY = `${SERVER_PRIVATE_URL}/gatekeeper/reports/attendance_average_per_activity/`
export const GET_GATEKEEPER_REPORT_ATTENDANCE_BY_ACTIVITY = `${SERVER_PRIVATE_URL}/gatekeeper/reports/attendance_by_activity/`
export const GET_GATEKEEPER_REPORT_EVENT_SUMMARY_BY_ACTIVITY = `${SERVER_PRIVATE_URL}/gatekeeper/reports/event_summary_by_activity/`
export const GET_GATEKEEPER_SCHOOLS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school/`
export const GET_GATEKEEPER_PROGRAM_GROUPS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/`
export const UPDATE_GATEKEEPER_PROGRAM_GROUP_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/`
export const DELETE_GATEKEEPER_PROGRAM_GROUP_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/`
export const GET_GATEKEEPER_PROGRAM_GROUP_CONSUMERS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/group_consumers/`
export const UPDATE_GATEKEEPER_PROGRAM_GROUP_CONSUMERS_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_school_activity_group/`
export const GET_GATEKEEPER_SCHOOL_STUDENTS_LIST_API_URL = `${SERVER_PRIVATE_URL}/gatekeeper_manage_consumers/`
export const CALL_PRIVATE_COORDINATOR_INVITE_SMS = `${SERVER_PRIVATE_URL}/coordinator_consumers/{slug}/invite_by_sms/`

export const LANGUAGES = [
  { value: "he", text: "עברית" },
  { value: "ar", text: "عربيه" },
  { value: "en", text: "English" },
]
export const LANGUAGE_TO_RTL = {
  "he": true,
  "ar": true,
  "en": false,
}
