import axios from "axios"
import {
  GET_GATEKEEPER_SCHOOLS_API_URL,
  GET_GATEKEEPER_SCHOOL_STUDENTS_LIST_API_URL,
} from "@/helpers/constants/constants"

const gatekeeperSchool = {
  getSchoolList(params = {}) {
    return axios.get(GET_GATEKEEPER_SCHOOLS_API_URL, { params })
  },
  getStudentList(params = {}) {
    return axios.get(GET_GATEKEEPER_SCHOOL_STUDENTS_LIST_API_URL, { params })
  },
}

export default gatekeeperSchool
