import Vue from "vue"
import i18n from "@/plugins/i18n"
import store from "@/vuex/store"
import {
  userToTabs,
  userToAccountButtons,
} from "connective/components/Navbar/config"

userToTabs.gatekeeper = [
  {
    id: "program-orders-navbar-tab",
    text: i18n.tc("general.program", 1),
    componentName: "GatekeeperProgramOrders",
    icon: "mdi-handshake",
  },
  {
    id: "user-invite-navbar-btn",
    text: i18n.t("invite.usersInvitation"),
    componentName: "GatekeeperInviteWrapper",
    icon: "mdi-account-multiple-plus",
  },
  {
    id: "events-navbar-btn",
    text: i18n.t("events.eventsSummary"),
    componentName: "GatekeeperEvents",
    icon: "mdi-calendar-heart",
  },
  {
    id: "statistics-btn",
    text: i18n.t("myActivity.statistics"),
    componentName: "GatekeeperStatistics",
    icon: "mdi-chart-bar",
  },
  {
    text: i18n.t("myActivity.myGroups"),
    componentName: "GatekeeperGroups",
    icon: "mdi-home-group",
  },
]

userToAccountButtons.gatekeeper = [
  {
    id: "profile-navbar-btn",
    text: i18n.t("general.profile"),
    icon: "mdi-account",
    onClick: () => Vue.$router.push({ name: "GatekeeperProfile" }),
  },
  {
    id: "theme-editor-navbar-btn",
    text: i18n.t("theme.themeEditor"),
    icon: "mdi-palette",
    onClick: () => Vue.$router.push({ name: "GatekeeperThemeEditor" }),
  },
  {
    id: "help-navbar-btn",
    text: i18n.t("general.pageExplanation"),
    icon: "mdi-help-rhombus",
    onClick: () => store.dispatch("introjs/triggerIntro"),
  },
  {
    id: "logout-navbar-btn",
    text: i18n.t("auth.logout"),
    icon: "mdi-export",
    onClick: () => store.dispatch("auth/logout"),
  },
]

export {
  userToTabs,
  userToAccountButtons,
}
