var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('pagination-actions-table',_vm._b({attrs:{"elevation":"0","value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)},"paginate":function($event){return _vm.$emit('paginate')},"action-one-click":function($event){return _vm.$emit('action-one-click', $event)},"action-two-click":function($event){return _vm.$emit('action-two-click', $event)}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'pagination-actions-table',_vm.$attrs,false)),_c('v-card-actions',{staticClass:"relative grey lighten-5 mt-3",attrs:{"introjs":"table-actions"}},[_c('v-btn',{class:{
        'glow-animation': !_vm.wasBtnOneClicked,
        'absolute-center': _vm.$vuetify.breakpoint.smAndUp,
      },attrs:{"outlined":"","data-testid":"table-footer-btn-one","color":_vm.footerBtnOneColor,"disabled":_vm.footerBtnOneDisabled},domProps:{"textContent":_vm._s(_vm.footerBtnOneText)},on:{"click":_vm.onBtnOneClick}}),_c('v-spacer'),_c('div',{staticClass:"pl-2"},[_c('v-btn',{attrs:{"text":"","data-testid":"table-footer-btn-two","color":_vm.footerBtnTwoColor,"disabled":_vm.footerBtnTwoDisabled || !_vm.footerBtnTwoText.length},domProps:{"textContent":_vm._s(_vm.footerBtnTwoText)},on:{"click":function($event){return _vm.$emit('footer-btn-two-click', $event)}}}),(_vm.$vuetify.breakpoint.smAndUp && !_vm.hideFooterIcons)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.triggerUpload}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.footerIconsColor}},[_vm._v(_vm._s(_vm.footerUploadIcon))])],1)]}}],null,false,473447047)},[_c('span',{staticClass:"px-3"},[_vm._v(_vm._s(_vm.footerUploadIconTooltip)+" CSV")])]):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp && !_vm.hideFooterIcons)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('file-download-request')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.footerIconsColor}},[_vm._v(_vm._s(_vm.footerDownloadIcon))])],1)]}}],null,false,1019584223)},[_c('span',{staticClass:"px-3"},[_vm._v(_vm._s(_vm.footerDownloadIconTooltip))])]):_vm._e()],1)],1),_c('v-file-input',{staticClass:"d-none",attrs:{"id":("upload-input-" + _vm._uid),"type":"file","accept":".csv"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }