import axios from "axios"
import {
  GET_GATEKEEPER_PROGRAM_ORDERS_LIST_API_URL,
  UPDATE_GATEKEEPER_PROGRAM_ORDER_API_URL,
} from "@/helpers/constants/constants"

const gatekeeperProgram = {
  getProgramOrders(params = {}) {
    return axios.get(GET_GATEKEEPER_PROGRAM_ORDERS_LIST_API_URL, { params })
  },

  updateProgramOrder(slug, data) {
    if (!slug) throw "updateProgramOrder: received empty slug"
    return axios.patch(
      `${UPDATE_GATEKEEPER_PROGRAM_ORDER_API_URL}${slug}/`,
      data
    )
  },
}

export default gatekeeperProgram
