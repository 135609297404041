<template>
  <div class="dashboard-wrapper">
    <navbar user-type="supervisor" />
    <v-row no-gutters>
      <v-col class="mt-16 px-6 mx-auto" md="9" xl="8">
        <router-view />
      </v-col>
    </v-row>
    <base-footer />
  </div>
</template>
<script>
import SupervisorDashboard from "connective/layouts/SupervisorDashboard"
import BaseFooter from "@/components/BaseFooter"

export default {
  components: { BaseFooter },
  extends: SupervisorDashboard,
}
</script>
<style scoped>
.dashboard-wrapper {
  margin-bottom: 100px;
}
</style>
