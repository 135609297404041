import i18n from "connective/plugins/i18n"

function getBrowserLocale() {
  return (navigator.userLanguage || navigator.language)
    .replace("-", "_")
    .toLowerCase()
    .split("_")[0]
}

function getUrlLocale() {
  const langStartIndex = location.pathname.indexOf("/", 1) + 1
  const langLength = location.pathname.indexOf("/", langStartIndex) - langStartIndex
  return location.pathname.substr(langStartIndex, langLength)
}

function getLocale() {
  const availableLocales = Object.keys(i18n.messages)
  const urlLocale = getUrlLocale()
  if (availableLocales.includes(urlLocale)) {
    return urlLocale
  }
  const browserLocale = getBrowserLocale()
  if (availableLocales.includes(browserLocale)) {
    return browserLocale
  }
}

i18n.locale = getLocale() || i18n.locale

export default i18n
