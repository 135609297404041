import axios from "axios"
import {
  GET_GATEKEEPER_EVENT_LIST_API_URL,
  GET_GATEKEEPER_EVENT_EXPORT_FILE_API_URL,
  GET_GATEKEEPER_REPORT_ATTENDANCE_AVG_PER_ACTIVITY,
  GET_GATEKEEPER_REPORT_ATTENDANCE_BY_ACTIVITY,
  GET_GATEKEEPER_REPORT_EVENT_SUMMARY_BY_ACTIVITY,
} from "@/helpers/constants/constants"

const gatekeeperEvent = {
  getEventList(params = {}) {
    return axios.get(GET_GATEKEEPER_EVENT_LIST_API_URL, { params })
  },
  getPastEventsExportFile(params = {}) {
    // :Object params: query params
    return axios.get(GET_GATEKEEPER_EVENT_EXPORT_FILE_API_URL, { params })
  },
  getEventsReportAttendanceAvgPerActivity(params = {}) {
    return axios.get(GET_GATEKEEPER_REPORT_ATTENDANCE_AVG_PER_ACTIVITY, { params })
  },
  getEventsReportAttendanceByActivity(params = {}) {
    return axios.get(GET_GATEKEEPER_REPORT_ATTENDANCE_BY_ACTIVITY, { params })
  },
  getEventsReportEventSummaryByActivity(params = {}) {
    return axios.get(GET_GATEKEEPER_REPORT_EVENT_SUMMARY_BY_ACTIVITY, { params })
  },
}

export default gatekeeperEvent
