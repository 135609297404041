import Api from "connective/api"
import theme from "@/api/theme"
import gatekeeper from "@/api/gatekeeper"
import gatekeeperProgram from "@/api/gatekeeperProgram"
import gatekeeperEvent from "@/api/gatekeeperEvent"
import gatekeeperInvite from "@/api/gatekeeperInvite"
import gatekeeperSchool from "@/api/gatekeeperSchool"
import gatekeeperProgramGroup from "@/api/gatekeeperProgramGroup"
import privateCoordinator from "@/api/privateCoordinator"

Api.theme = theme
Api.gatekeeper = gatekeeper
Api.gatekeeperProgram = gatekeeperProgram
Api.gatekeeperEvent = gatekeeperEvent
Api.gatekeeperInvite = gatekeeperInvite
Api.gatekeeperSchool = gatekeeperSchool
Api.gatekeeperProgramGroup = gatekeeperProgramGroup
Api.privateCoordinator = privateCoordinator

export default Api
