<template>
  <div class="dashboard-wrapper">
    <navbar user-type="instructor" />
    <router-view class="router" />
    <base-footer />
  </div>
</template>
<script>
import InstructorDashboard from "connective/layouts/InstructorDashboard"
import BaseFooter from "@/components/BaseFooter"

export default {
  components: { BaseFooter },
  extends: InstructorDashboard,
}
</script>
<style scoped>
.dashboard-wrapper {
  margin-bottom: 100px;
}
</style>
