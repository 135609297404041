<template>
  <div class="dashboard-wrapper">
    <navbar user-type="gatekeeper" />
    <v-row no-gutters>
      <v-col class="mt-16 px-6 mx-auto" md="9" xl="8">
        <router-view />
      </v-col>
    </v-row>
    <base-footer />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar/Navbar"
import BaseFooter from "@/components/BaseFooter"

export default {
  name: "GatekeeperDashboard",
  components: { Navbar, BaseFooter },
}
</script>
<style scoped>
.dashboard-wrapper {
  margin-bottom: 100px;
}
</style>
