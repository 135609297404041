<template>
  <v-row no-gutters>
    <v-col class="px-2 secondary relative" cols="12" lg="5">
      <router-view />
      <footer class="mx-1 my-3 absolute-bottom">
        <language-select />
      </footer>
    </v-col>
    <v-col class="relative d-none d-lg-block overflow-hidden" lg="7">
      <v-img class="w-35 absolute-center" :src="bg" />
    </v-col>
  </v-row>
</template>

<script>
import Welcome from "connective/layouts/Welcome"
import { BACKGROUNDS } from "@/helpers/constants/images"
import LanguageSelect from "@/components/LanguageSelect"
import store from "@/vuex/store"

export default {
  components: { LanguageSelect },
  extends: Welcome,
  data() {
    return {
      bg: store.state.theme.theme.welcomeBg || BACKGROUNDS.welcome
    }
  }
}
</script>
