import guards, { chainGuards } from "connective/router/guards"
import store from "@/vuex/store.js"
import { SERVER } from "@/helpers/constants/constants"

guards.applyTheme = async function (to, from, next) {
  await store.dispatch("theme/getTheme")
  await store.dispatch("theme/applyThemeColor")
  next()
}

guards.gatekeeperOnly = function (to, from, next) {
  if (store.state.user.userDetails.userType === SERVER.userTypes.gatekeeper) {
    return next()
  }
  next({ name: "Login", params: to.params })
}
guards.populateGatekeeperData = async function (to, from, next) {
  await Promise.all([
    store.dispatch("gatekeeper/getProfile"),
    store.dispatch("user/getUserDetails"),
  ])
  next()
}

const connectiveCheckRegistration = guards.checkRegistrationStatus
guards.checkRegistrationStatus = async function (to, from, next) {
  const userDetails = await store.dispatch("user/getUserDetails")
  if (SERVER.userTypes.gatekeeper === userDetails.userType) {
    return next({ name: "GatekeeperDashboard", params: to.params })
  }
  connectiveCheckRegistration(to, from, next)
}

export { chainGuards }
export default guards
