var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-pagination-table',_vm._b({attrs:{"multi-sort":"","headers":_vm.tableHeaders,"items":_vm.items,"no-data-text":_vm.noDataText,"loading":_vm.loading,"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)},"paginate":function($event){return _vm.$emit('paginate')}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(_vm.totalActions >= 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-3",attrs:{"data-testid":"actions-table-action-one","introjs":"actions-table-icon-one","size":"20","color":_vm.actionOneIconColor},on:{"click":function($event){return _vm.$emit('action-one-click', item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.actionOneIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.actionOneIconTooltip))])]):_vm._e(),(_vm.totalActions >= 2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-3",attrs:{"data-testid":"actions-table-action-two","size":"20","color":_vm.actionTwoIconColor},on:{"click":function($event){return _vm.$emit('action-two-click', item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.actionTwoIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.actionTwoIconTooltip))])]):_vm._e()],1)]}}],null,true)},'base-pagination-table',Object.assign({}, _vm.$props, _vm.$attrs),false))}
var staticRenderFns = []

export { render, staticRenderFns }