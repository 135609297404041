import axios from "axios"
import {
  CALL_PRIVATE_COORDINATOR_INVITE_SMS,
} from "@/helpers/constants/constants"

const privateCoordinator = {

  sendInviteSMS(slug) {
    const apiURL = CALL_PRIVATE_COORDINATOR_INVITE_SMS.replace("{slug}", slug)
    return axios.post(apiURL)
  },

}

export default privateCoordinator
