<template>
  <div>
    <canvas ref="barChart" />
  </div>
</template>

<script>
import Chart from "chart.js/auto"

export default {
  props: {
    labels: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      default() {
        return [
          "#A0E7E5",
          "#FFAEBC",
          "#B4F8C8",
          "#FBE7C6",
          "#A49393",
          "#67595E",
        ]
      },
    },
    datasets: {
      // e.g., [ { label, data, backgroundColor }, ... ]
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {}
      },
    },
    stacked: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.createChart({
      datasets: this.datasets,
      labels: this.labels,
    })
  },
  computed: {
    opts() {
      // add stacking and responsiveness
      const totalHeight= this.datasets[0].data.map((n, i) => n + this.datasets[1].data[i])
      return Object.assign(
        {
          responsive: true,
          animation: {
            duration: 2500
          },
          scales: {
            x: {
              stacked: this.stacked,
            },
            y: {
              max: Math.ceil(Math.max(...totalHeight) * 1.1),
              stacked: this.stacked,
            },
          },
        },
        this.options
      )
    },
  },
  methods: {
    createChart(chartData) {
      const canvas = this.$refs.barChart
      const options = {
        type: "bar",
        data: chartData,
        options: this.opts,
      }
      new Chart(canvas, options)
    },
  },
}
</script>
