<template>
  <v-select
    dark
    filled
    v-bind="$attrs"
    class="lang-select mx-auto"
    append-icon="mdi-web"
    :value="$i18n.locale"
    :items="LANGUAGES"
    @change="setLanguage"
  />
</template>

<script>
import { LANGUAGES } from "@/helpers/constants/constants"

export default {
  inheritAttrs: false,
  data() {
    return { LANGUAGES }
  },
  methods: {
    setLanguage(lang) {
      const url = document.location.toString()
      location.replace(url.replace(`/${this.$i18n.locale}/`, `/${lang}/`))
    },
  },
}
</script>
<style lang="scss" scoped>
.lang-select {
  width: 300px;
}
</style>
