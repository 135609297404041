var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"absolute-center py-12 px-7",attrs:{"width":"320","elevation":"16"}},[_c('v-card-title',{staticClass:"text-h5 justify-center mb-6"},[_vm._v(" "+_vm._s(_vm.mode === "init" ? _vm.$t("general.welcomeToConnective") : _vm.$t("auth.passwordReset"))+" ")]),(_vm.mode === 'init')?_c('v-card-subtitle',{staticClass:"text-h6 text-center mb-8"},[_vm._v(" "+_vm._s(_vm.$t("auth.toStartPleaseChooseNewPassword"))+" ")]):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|noHebrew|noArabic|strongPass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-5",attrs:{"autofocus":"","append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"type":_vm.showPass ? 'text' : 'password',"name":"password","label":_vm.$t('auth.newPassword')},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"passwordConfirmation","rules":"required|passConfirm:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-5",attrs:{"error-messages":errors,"type":"password","name":"passwordConfirmation","label":_vm.$t('auth.reEnterPassword')},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),_c('div',{staticClass:"overline line-height-1-7 pt-3"},[_vm._v(" * "+_vm._s(_vm.$t("auth.chooseAPasswordYouWillRemember"))+". ")]),_c('div',{staticClass:"overline line-height-1-7 pt-2"},[_vm._v(" * "+_vm._s(_vm.$t("validation.strongPass"))+". ")])]}}],null,true)}),(_vm.mode === 'init')?_c('validation-provider',{attrs:{"name":"tou","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"name":"tou","color":"primary","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$t("general.iAcceptThe"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.isTermsOfUseAgreementAccepted),callback:function ($$v) {_vm.isTermsOfUseAgreementAccepted=$$v},expression:"isTermsOfUseAgreementAccepted"}})]}}],null,true)}):_vm._e(),_c('a',{attrs:{"href":_vm.TERMS_OF_USE_URL,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("termsOfUse.termsOfUse")))]),_c('v-btn',{staticClass:"white--text mt-6",attrs:{"type":"submit","color":"primary","elevation":"3","disabled":invalid,"block":""},domProps:{"textContent":_vm._s(_vm.$t('auth.finishRegistration'))}}),_c('v-btn',{staticClass:"mt-4",attrs:{"outlined":"","block":"","color":"primary","elevation":"3","to":{ name: 'Login' }},domProps:{"textContent":_vm._s(_vm.$t('general.homepage'))}})],1)]}}])})],1),_c('modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.popupMsg !== ''),expression:"popupMsg !== ''"}],attrs:{"redirectUrl":_vm.modalRedirectUrl},on:{"close":function($event){_vm.popupMsg = ''}},scopedSlots:_vm._u([(_vm.modalRedirectUrl)?{key:"btn",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("general.homepage"))+" ")]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(_vm.popupMsg)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }