<template>
  <div v-if="theme">
    <h1 v-text="$t('theme.themeEditor')" class="px-0 mb-5" />
    <h2
      v-text="$t('theme.customizeThePlatformUsingColorsAndLogosOfYourChoice')"
      class="pb-12"
    />
    <v-card elevation="0" :class="{ 'w-90 pa-10': $vuetify.breakpoint.sm }">
      <div class="d-flex flex-wrap justify-space-between">
        <color-picker
          class="mx-5"
          :label="$t('theme.primaryColor')"
          v-model="theme.colorPrimary"
        />
        <color-picker
          class="mx-5"
          :label="$t('theme.secondaryColor')"
          v-model="theme.colorSecondary"
        />
        <color-picker
          class="mx-5"
          :label="$t('theme.secondaryColorLightenOne')"
          v-model="theme.colorSecondaryLightenOne"
        />
        <color-picker
          class="mx-5"
          :label="$t('theme.secondaryColorLightenTwo')"
          v-model="theme.colorSecondaryLightenTwo"
        />
        <color-picker
          class="mx-5"
          :label="$t('theme.successColor')"
          v-model="theme.colorSuccess"
        />
      </div>
      <div class="my-5 d-flex flex-wrap justify-space-between">
        <v-file-input
          accept="image/*"
          v-model="theme.logo"
          :label="$t('theme.logo')"
          class="mx-5"
        />
        <v-file-input
          accept="image/*"
          v-model="theme.navbarBg"
          :label="$t('theme.navbarBackground')"
          class="mx-5"
        />
        <v-file-input
          accept="image/*"
          v-model="theme.welcomeBg"
          :label="$t('theme.loginPageBackground')"
          class="mx-5"
        />
      </div>
      <v-btn
        class="my-8 mx-5"
        color="primary"
        :loading="loading"
        @click="onSubmit"
      >
        {{ $t("userActions.save") }}
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import cloneDeep from "lodash/cloneDeep"
import store from "@/vuex/store"
import Api from "@/api"
import Utils from "@/helpers/utils"
import ColorPicker from "@/components/ColorPicker"

export default {
  components: { ColorPicker },
  beforeRouteEnter(to, from, next) {
    const theme = cloneDeep(store.state.theme.theme)
    theme.logo = null
    theme.navbarBg = null
    theme.welcomeBg = null
    next(vm => (vm.theme = theme))
  },
  data() {
    return {
      theme: null,
      loading: false,
    }
  },
  methods: {
    ...mapActions("theme", ["updateTheme"]),
    ...mapActions("snackbar", ["showMessage"]),
    cleanThemePayload(theme) {
      delete theme.slug
      if (!theme.logo) {
        delete theme.logo
      }
      if (!theme.navbarBg) {
        delete theme.navbarBg
      }
      if (!theme.welcomeBg) {
        delete theme.welcomeBg
      }
      return Utils.objectToFormData(theme)
    },
    async onSubmit() {
      try {
        this.loading = true
        await this.updateTheme({
          slug: this.theme.slug,
          theme: this.cleanThemePayload(this.theme),
        })
        location.reload()
      } catch (err) {
        this.showMessage(Api.utils.parseResponseError(err))
        this.loading = false
      }
    },
  },
}
</script>
