<template>
  <v-menu v-model="menu" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-sheet
        class="d-flex justify-space-between avatar-wrapper"
        color="transparent"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon :class="{ rotate: menu }" size="55" dark>
          mdi-menu-down
        </v-icon>
        <avatar
          v-if="theme.useAvatarFigure"
          v-bind="attrs"
          v-on="on"
          class="avatar cursor-pointer"
          :avatar-options="avatarOptions"
        />
        <v-avatar
          v-else
          v-bind="attrs"
          v-on="on"
          color="primary"
          class="avatar cursor-pointer"
        >
          <span>{{ nameInitials }}</span>
        </v-avatar>
      </v-sheet>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <avatar
              v-if="theme.useAvatarFigure"
              :avatar-options="avatarOptions"
            />
            <v-icon size="30" v-else>mdi-card-account-details-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="name" />
            <v-list-item-subtitle v-text="email" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list class="pb-5">
        <v-list-item-group>
          <v-list-item
            v-for="btn in buttons"
            :key="btn.id"
            :data-testid="btn.id"
            @click="btn.onClick"
          >
            <v-list-item-icon>
              <v-icon color="primary" v-text="btn.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="btn.text" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from "vuex"
import AccountMenu from "connective/components/AccountMenu"

export default {
  extends: AccountMenu,
  computed: {
    ...mapState("theme", ["theme"]),
    nameInitials() {
      if (this.name.length < 2) {
        return ""
      }
      const lastNameIndex = this.name.indexOf(" ") + 1
      if (lastNameIndex > 0 && lastNameIndex < this.name.length) {
        return `${this.name[0]}${this.name[lastNameIndex]}`
      }
      return this.name.substring(0, 2)
    },
  },
}
</script>
