import { render, staticRenderFns } from "./PaginationChipGroup.vue?vue&type=template&id=49e8f79e&scoped=true&"
import script from "./PaginationChipGroup.vue?vue&type=script&lang=js&"
export * from "./PaginationChipGroup.vue?vue&type=script&lang=js&"
import style0 from "./PaginationChipGroup.vue?vue&type=style&index=0&id=49e8f79e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e8f79e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
installComponents(component, {VChip,VChipGroup})
