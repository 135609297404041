import Api from "@/api"

function getDefaultState() {
  return {
    coordinatorList: [],
  }
}

const gatekeeperInvite = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    ADD_COORDINATORS_TO_LIST(state, coordinatorList) {
      state.coordinatorList.push(...coordinatorList)
    },
    SET_COORDINATOR_LIST(state, coordinatorList) {
      state.coordinatorList = coordinatorList
    },
    ADD_VENDORS_TO_LIST(state, vendorList) {
      state.vendorList.push(...vendorList)
    },
    SET_VENDOR_LIST(state, vendorList) {
      state.vendorList = vendorList
    },
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async getCoordinatorList(
      { commit, state, rootGetters },
      { override = true, usePagination = true }
    ) {
      const params = usePagination ? rootGetters["pagination/apiParams"] : {}
      const mutation = override
        ? "SET_COORDINATOR_LIST"
        : "ADD_COORDINATORS_TO_LIST"
      let res = await Api.gatekeeperInvite.getCoordinatorList(params)
      commit(mutation, res.data.results)
      return state.coordinatorList
    },
    addCoordinator(ctx, coordinator) {
      return Api.gatekeeperInvite.addCoordinator(coordinator)
    },
    deleteCoordinators(ctx, coordinatorSlugs) {
      return Api.gatekeeperInvite.deleteCoordinators(coordinatorSlugs)
    },
    editCoordinator(ctx, { slug, coordinator }) {
      return Api.gatekeeperInvite.editCoordinator(slug, coordinator)
    },
    async getVendorList(
      { commit, state, rootGetters },
      { override = true, usePagination = true }
    ) {
      // :boolean override: whether to override the list or not (i.e., extend)
      const params = usePagination ? rootGetters["pagination/apiParams"] : {}
      const mutation = override ? "SET_VENDOR_LIST" : "ADD_VENDORS_TO_LIST"
      let res = await Api.gatekeeperInvite.getVendorList(params)
      commit(mutation, res.data.results)
      return state.vendorList
    },
    addVendor(ctx, vendor) {
      return Api.gatekeeperInvite.addVendor(vendor)
    },
    deleteVendors(ctx, vendorSlugs) {
      return Api.gatekeeperInvite.deleteVendors(vendorSlugs)
    },
    editVendor(ctx, { slug, vendor }) {
      return Api.gatekeeperInvite.editVendor(slug, vendor)
    },
  },
}

export default gatekeeperInvite
