import axios from "axios"
import config from "connective/api/config"

function addSiteIdQueryParam() {
  axios.defaults.params = axios.defaults.params || {}
  axios.defaults.params["site_id"] = window.location.pathname.split("/")[1]
}

const connectiveInitSettings = config.initAxiosSettings
config.initAxiosSettings = () => {
  addSiteIdQueryParam()
  return connectiveInitSettings()
}

export default config
