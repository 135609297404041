var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-12 mb-lg-0",attrs:{"cols":"12","lg":"7"}},[_c('div',{staticClass:"pb-12"},[_c('h1',{staticClass:"mb-5",domProps:{"textContent":_vm._s(_vm.$t('program.mediaUpload'))}}),_c('h2',{domProps:{"textContent":_vm._s(_vm.$t('program.uploadVideosAndImagesSoSchoolsCanView'))}})]),_c('carousel',{attrs:{"media-list":_vm.mediaList.length ? _vm.mediaList : _vm.mediaListPlaceholder},model:{value:(_vm.currentMediaIndex),callback:function ($$v) {_vm.currentMediaIndex=$$v},expression:"currentMediaIndex"}}),_c('div',{staticClass:"text-center pt-12 relative"},[_c('v-btn',{staticClass:"mx-4",attrs:{"fab":"","color":"error"},on:{"click":_vm.triggerMediaDelete}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"mx-4",attrs:{"fab":"","data-testid":"add-media-btn","color":"success"},on:{"click":_vm.triggerMediaUpload}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"absolute-left",attrs:{"data-testid":"finish-btn","x-large":"","outlined":"","color":"success"},domProps:{"textContent":_vm._s(_vm.$t('userActions.finish'))},on:{"click":function($event){return _vm.$router.push({
              name: 'VendorDetailProgram',
              params: { programSlug: _vm.programSlug },
            })}}})],1)],1),_c('v-col',{staticClass:"px-10",attrs:{"cols":"12","lg":"5"}},[(!_vm.$vuetify.breakpoint.xs)?_c('sticky-note',[_c('b',[_vm._v(_vm._s(this.$t("general.didYouKnow?")))]),_c('div',{staticClass:"pt-5 sticky-span",domProps:{"textContent":_vm._s(
            _vm.$t(
              'program.uploadingImagesAndVideosCanImproveTheProgramPopularity!'
            )
          )}})]):_vm._e()],1)],1),_c('modal-approve',{on:{"approve":function($event){return _vm.deleteMedia(_vm.currentMediaIndex)}},model:{value:(_vm.isApproveModalOpen),callback:function ($$v) {_vm.isApproveModalOpen=$$v},expression:"isApproveModalOpen"}},[_vm._v(" "+_vm._s(this.$t( "confirm.thisActionWillDeleteTheMediaYouAreCurrentlyWatching-Proceed?" ))+" ")]),_c('upload-modal',{on:{"upload":_vm.uploadMedia},model:{value:(_vm.isUploadModalOpen),callback:function ($$v) {_vm.isUploadModalOpen=$$v},expression:"isUploadModalOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }