<template>
  <v-footer absolute padless dark class="footer">
    <div class="mx-auto">
      <language-select class="py-5" />
    </div>
  </v-footer>
</template>
<script>
import LanguageSelect from "@/components/LanguageSelect"
export default {
  components: { LanguageSelect },
}
</script>

<style scoped>
.footer {
  margin-top: 100px;
  height: 85px;
  overflow: hidden;
}
</style>
