<template>
  <div class="pa-3 pa-sm-10">
    <route-tabs :tabs="tabs" introjs="tabs" />
    <v-row>
      <v-col class="mx-auto" sm="11" lg="9">
        <router-view />
      </v-col>
    </v-row>
    <div class="overline text-center">
      *
      {{
        this.$t(
          "invite.invitingUserOrChangingExistingEmailWillSendAnInviteToTheInviteesMailbox"
        )
      }}.
    </div>
  </div>
</template>

<script>
import RouteTabs from "@/components/RouteTabs"
import introjsSubscribeMixin from "@/mixins/introJs/introjsSubscribeMixin"

export default {
  name: "GatekeeperInviteWrapper",
  components: { RouteTabs },
  mixins: [introjsSubscribeMixin],
  data() {
    return {
      tabs: [
        {
          componentName: "GatekeeperInviteCoordinators",
          text: this.$t("invite.inviteCoordinators"),
        },
        {
          componentName: "GatekeeperInviteVendors",
          text: this.$t("invite.inviteVendors"),
        },
      ],
    }
  },
}
</script>
