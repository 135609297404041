<template>
  <div>
    <h1 v-text="$t('myActivity.statistics')" class="mb-5" />
    <h2
      v-text="$t('general.thisIsAnExperimentalFeature-feedbacksAreMostWelcome')"
      class="pb-12"
    />
    <v-container grid-list-md text-xs-center fluid>
      <v-layout row wrap fluid>
        <v-flex xs12>
          <v-card>
            <v-card-title>{{
              $t("charts.title_attendance_avg_per_activity")
            }}</v-card-title>
            <bar-chart
              v-if="attendanceAvgPerActivityDatasets"
              class="mx-auto"
              :datasets="attendanceAvgPerActivityDatasets"
              :labels="attendanceAvgPerActivityLabels"
            />
          </v-card>
        </v-flex>
        <v-flex xs6>
          <v-card>
            <v-card-title>{{
              $t("charts.title_attendance_by_activity")
            }}</v-card-title>
            <doughnut-chart
              v-if="attendanceByActivityDatasets"
              class="mx-auto"
              :data="attendanceByActivityDatasets"
              :labels="attendanceByActivityLabels"
              :colors="attendanceByActivityColors"
            />
          </v-card>
        </v-flex>
        <v-flex xs6>
          <v-card>
            <v-card-title>{{
              $t("charts.title_event_summary_by_activity")
            }}</v-card-title>
            <horizontal-bar-chart
              v-if="eventSummaryByActivityDatasets"
              class="mx-auto"
              :datasets="eventSummaryByActivityDatasets"
              :labels="eventSummaryByActivityLabels"
            />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import store from "@/vuex/store"
import BarChart from "@/components/Charts/BarChart"
import DoughnutChart from "@/components/Charts/Doughnut"
import HorizontalBarChart from "@/components/Charts/HorizontalBarChart"

export default {
  components: { BarChart, DoughnutChart, HorizontalBarChart },

  async beforeRouteEnter(to, from, next) {
    const [
      attendanceAvgPerActivityData,
      attendanceByActivityData,
      eventSummaryByActivityData,
    ] = await Promise.all([
      store.dispatch("gatekeeperEvent/getEventsReportAttendanceAvgPerActivity"),
      store.dispatch("gatekeeperEvent/getEventsReportAttendanceByActivity"),
      store.dispatch("gatekeeperEvent/getEventsReportEventSummaryByActivity"),
    ])
    next(vm => {
      const attendanceAvgPerActivityLabels =
        attendanceAvgPerActivityData.activityNames
      const attendanceAvgPerActivityDatasets = [
        {
          label: vm.$t("charts.participants"),
          data: attendanceAvgPerActivityData.avgParticipated,
          backgroundColor: vm.$vuetify.theme.themes.light.primary,
        },
        {
          label: vm.$t("charts.absent"),
          data: attendanceAvgPerActivityData.avgAbsent,
          backgroundColor: vm.$vuetify.theme.themes.light.secondary,
        },
      ]
      vm.attendanceAvgPerActivityLabels = attendanceAvgPerActivityLabels
      vm.attendanceAvgPerActivityDatasets = attendanceAvgPerActivityDatasets

      const attendanceByActivityLabels = attendanceByActivityData.activityNames
      const attendanceByActivityDatasets =
        attendanceByActivityData.attendedForActivity

      vm.attendanceByActivityLabels = attendanceByActivityLabels
      vm.attendanceByActivityDatasets = attendanceByActivityDatasets

      const eventSummaryByActivityLabels =
        eventSummaryByActivityData.activityName
      const eventSummaryByActivityDatasets = [
        {
          label: vm.$t("charts.has_summary"),
          data: eventSummaryByActivityData.countHasSummary,
          backgroundColor: vm.$vuetify.theme.themes.light.primary,
        },
        {
          label: vm.$t("charts.has_no_summary"),
          data: eventSummaryByActivityData.countHasntSummary,
          backgroundColor: vm.$vuetify.theme.themes.light.secondary,
        },
      ]
      vm.eventSummaryByActivityLabels = eventSummaryByActivityLabels
      vm.eventSummaryByActivityDatasets = eventSummaryByActivityDatasets
    })
  },

  data() {
    const colorList = Object.values(this.$vuetify.theme.themes.light)
    return {
      attendanceAvgPerActivityLabels: null,
      attendanceAvgPerActivityDatasets: null,
      attendanceByActivityLabels: null,
      attendanceByActivityDatasets: null,
      attendanceByActivityColors: [
        ...colorList,
        "#B4F8C8",
        "#FBE7C6",
        "#A49393",
        "#67595E",
        "#A0E7E5",
        "#FFAEBC",
      ],
      eventSummaryByActivityLabels: null,
      eventSummaryByActivityDatasets: null,
    }
  },
}
</script>
