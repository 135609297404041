<template>
  <div>
    <v-toolbar dark prominent>
      <template slot="img">
        <v-img style="filter: brightness(65%)" :src="bg" height="300" />
      </template>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.xs"
        introjs="navigation"
        @click="mobileDrawer = true"
      />
      <img
        v-if="logo"
        class="mx-4 my-8 my-sm-10"
        style="max-height: 80px; max-width: 150px"
        :src="logo"
      />
      <v-toolbar-title
        v-else
        class="px-md-6"
        :class="{ absolute: $vuetify.breakpoint.xs }"
        v-text="$t('general.connective')"
      />
      <v-spacer />
      <div
        class="px-md-6 align-self-center"
        introjs="navbar-account-menu"
        data-testid="navbar-account-menu"
      >
        <account-menu
          :avatar-options="profile.profilePicture"
          :name="userDetails.name"
          :email="userDetails.email"
          :buttons="accountButtons"
        />
      </div>
      <template v-if="!$vuetify.breakpoint.xs" v-slot:extension>
        <route-tabs :tabs="tabs" color="primary" class="px-6" />
      </template>
    </v-toolbar>
    <navigation-drawer
      v-if="$vuetify.breakpoint.xs"
      v-model="mobileDrawer"
      :nav-items="tabs"
      :title="$t('general.connective')"
      :subtitle="$t('general.navigationMenu')"
      disable-resize-watcher
      disable-route-watcher
    />
  </div>
</template>

<script>
import store from "@/vuex/store"
import { BACKGROUNDS } from "@/helpers/constants/images"
import Navbar from "connective/components/Navbar/Navbar"

export default {
  extends: Navbar,
  data() {
    return {
      logo: store.state.theme.theme.logo,
      bg: store.state.theme.theme.navbarBg || BACKGROUNDS.navbar,
    }
  },
}
</script>
