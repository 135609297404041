var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-md-15 mx-auto px-4 px-md-16 py-10",attrs:{"max-width":"800","elevation":_vm.$vuetify.breakpoint.xs ? 0 : 3}},[_c('v-card-title',{staticClass:"px-0 pb-0",domProps:{"textContent":_vm._s(_vm.$t('events.eventSummary'))}}),_c('a',{staticClass:"d-block mb-4 w-fit-content",class:{ 'cursor-initial': !_vm.event.activityWebsite },attrs:{"target":"_blank","href":_vm.event.activityWebsite}},[_c('v-card-subtitle',{staticClass:"px-0",domProps:{"textContent":_vm._s(_vm.event.activityName)}},[_vm._v(" "+_vm._s(_vm.event.activityName)+" ")])],1),_c('title-to-text',{attrs:{"title":_vm.$t('groups.groupName'),"text":_vm.event.schoolGroupName || _vm.$t('errors.unavailable')}}),_c('title-to-text',{attrs:{"title":_vm.$t('time.startTime'),"text":_vm.parseDate(_vm.event.startTime) || _vm.$t('errors.unavailable')}}),_c('title-to-text',{attrs:{"title":_vm.$t('time.endTime'),"text":_vm.parseDate(_vm.event.endTime) || _vm.$t('errors.unavailable')}}),_c('title-to-text',{attrs:{"title":_vm.$t('myActivity.location'),"text":_vm.event.locationsName || _vm.$t('errors.empty')}}),_c('validation-observer',{staticClass:"mt-16 form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticStyle:{"background":"#feece5"},attrs:{"introjs":"confidential"}},[_c('v-row',{staticClass:"pt-16 px-2 px-sm-9 pb-5",attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',[_c('v-img',{attrs:{"src":_vm.CONFIDENTIAL_WATERMARK,"alt":"confidential"}})],1),_c('v-card-text',{domProps:{"textContent":_vm._s(((_vm.$t('posts.thisPostIsNotVisibleForStudents')) + "."))}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tribute',{attrs:{"options":_vm.tributeOptions}},[_c('v-textarea',{staticClass:"my-6",attrs:{"autofocus":"","outlined":"","persistent-hint":"","hint":_vm.$t('events.use@toTagStudents'),"label":_vm.$t(
                  'events.summaryGeneralNotes-unusualEventsStudentsBehaviorEtc'
                )},model:{value:(_vm.summaryGeneralNotes),callback:function ($$v) {_vm.summaryGeneralNotes=$$v},expression:"summaryGeneralNotes"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"5"}},[_c('v-select',{staticClass:"my-6",attrs:{"outlined":"","items":_vm.consumerchoices,"label":_vm.$t('events.attendance'),"multiple":"","dense":""},model:{value:(_vm.attendedConsumers),callback:function ($$v) {_vm.attendedConsumers=$$v},expression:"attendedConsumers"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"5"}},[_c('v-select',{staticClass:"my-6",attrs:{"outlined":"","items":[1, 2, 3, 4, 5, 6, 7, 8, 9, 10],"label":_vm.$t('events.summaryGeneralRating'),"dense":""},model:{value:(_vm.summaryGeneralRating),callback:function ($$v) {_vm.summaryGeneralRating=$$v},expression:"summaryGeneralRating"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"5"}},[_c('v-select',{staticClass:"my-6",attrs:{"outlined":"","items":[1, 2, 3, 4, 5, 6, 7, 8, 9, 10],"label":_vm.$t('events.summaryChildrenBehavior'),"dense":""},model:{value:(_vm.summaryChildrenBehavior),callback:function ($$v) {_vm.summaryChildrenBehavior=$$v},expression:"summaryChildrenBehavior"}})],1)],1)],1),_c('v-checkbox',{staticClass:"mt-16",attrs:{"label":_vm.$t('userActions.addPublicPost')},model:{value:(_vm.addPost),callback:function ($$v) {_vm.addPost=$$v},expression:"addPost"}}),(_vm.addPost)?_c('v-card',{attrs:{"introjs":"public","elevation":"0"}},[_c('v-card-title',{staticClass:"px-0",domProps:{"textContent":_vm._s(_vm.$t('userActions.addPost'))}}),_c('v-card-subtitle',{staticClass:"px-0",domProps:{"textContent":_vm._s(((_vm.$t('posts.thisPostWillBeVisibleForEveryone')) + "."))}}),_c('v-row',{staticClass:"my-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-tribute',{attrs:{"options":_vm.tributeOptions}},[_c('v-textarea',{attrs:{"outlined":"","persistent-hint":"","hint":_vm.$t('events.use@toTagStudents'),"label":_vm.$t('events.postContent'),"error-messages":errors},model:{value:(_vm.feedContent),callback:function ($$v) {_vm.feedContent=$$v},expression:"feedContent"}})],1)]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"rules":"size:12000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-file-input',{ref:"fileInput",attrs:{"accept":".gif,.jpg,.jpeg,.png","outlined":"","multiple":"","append-icon":"mdi-paperclip","prepend-icon":"","persistent-hint":"","error-messages":errors,"hint":_vm.$t('userActions.holdCtrlKeyToUploadMultipleMediaFiles'),"label":_vm.$t('userActions.addImages')},on:{"change":_vm.compressImages,"click:append":function($event){_vm.$refs.fileInput.$el.querySelector('input').click()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"},domProps:{"textContent":_vm._s(text)}})]}}],null,true),model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}})]}}],null,true)}),_vm._l((_vm.imageUrls),function(url){return _c('v-img',{key:url,attrs:{"src":url}})})],2)],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"mt-9 mb-6"},[_c('v-btn',{attrs:{"large":"","type":"submit","color":"primary","elevation":"3","loading":_vm.submitting,"disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("userActions.save"))+" ")]),_c('v-btn',{staticClass:"mx-3 white--text",attrs:{"large":"","color":"primary","outlined":""},domProps:{"textContent":_vm._s(_vm.$t('userActions.back'))},on:{"click":function($event){return _vm.$router.push({ name: 'InstructorUnsummarizedEvents' })}}})],1)]}}])}),_c('modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalOpen),expression:"isModalOpen"}],attrs:{"redirectComponentName":"InstructorUnsummarizedEvents"}},[_vm._v(" "+_vm._s(_vm.modalMsg)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }